import {
  useEffect,
  useState,
  useRef,
  useCallback,
  useLayoutEffect,
  ReactNode,
  RefObject,
  forwardRef,
  useMemo,
  ReactElement,
} from 'react';
import {css} from '@emotion/react';
import {Property} from 'csstype';
import {Overlay} from './Overlay';

import {
  useCombinedRefs, useTheme,
} from '../utils/hooks';

export interface DropdownProps {
  className?: string;
  children: ReactNode;
  parentRef: RefObject<HTMLElement | undefined>;
  onHover?: string;
  distance?: number;
  alignTop?: boolean;
  grow?: boolean;
  shrink?: boolean;
  alignBottom?: boolean;
  alignRight?: boolean;
  alignCenter?: boolean;
  positionRight?: boolean;
  positionLeft?: boolean;
  width?: number;
  onClose?: () => void;
  /** Clicking on this dropdown should close other dropdowns */
  base?: boolean;
  background?: Property.Background;
  shadow?: Property.BoxShadow | boolean;
  border?: Property.Border | boolean;
  borderTop?: Property.BorderTop | boolean;
  borderRight?: Property.BorderRight | boolean;
  borderBottom?: Property.BorderBottom | boolean;
  borderLeft?: Property.BorderLeft | boolean;
  borderRadius?: Property.BorderRadius;
  borderTopLeftRadius?: Property.BorderTopLeftRadius;
  borderTopRightRadius?: Property.BorderTopRightRadius;
  borderBottomLeftRadius?: Property.BorderBottomLeftRadius;
  borderBottomRightRadius?: Property.BorderBottomRightRadius;
}

// Wrapper to render something positioned next to an element in fixed screen space
export const Dropdown = forwardRef<HTMLDivElement, DropdownProps>((
  {
    className,
    alignRight = false,
    positionRight = false,
    positionLeft = false,
    alignTop = false,
    alignBottom = false,
    alignCenter = false,
    grow = false,
    shrink = false,
    parentRef,
    distance = 0,
    width,
    onClose,
    base,
    background,
    shadow,
    border,
    borderTop,
    borderRight,
    borderBottom,
    borderLeft,
    borderRadius,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderBottomLeftRadius,
    borderBottomRightRadius,
    children,
  },
  ref,
): ReactElement | null => {
  const theme = useTheme();

  const [parentRect, setParentRect] = useState<DOMRect | null>(null);
  const [internalPosition, setInternalPosition] = useState<{left: number; top: number}>({
    left: 0,
    top: 0,
  });
  const [position, setPosition] = useState<{left: number; top: number}>(internalPosition);
  const [freezePosition, setFreezePosition] = useState(false);
  const [size, setSize] = useState<{width: number; height: number}>({
    width: 0,
    height: 0,
  });
  const [windowSize, setWindowSize] = useState<{width: number; height: number}>({
    width: 0,
    height: 0,
  });
  const [positionTop, setPositionTop] = useState(false);
  const [hidden, setHidden] = useState(false);
  const dropdownRef = useRef<HTMLElement>();
  const element = dropdownRef.current;
  const portalRef = useRef<HTMLElement>(null);

  useLayoutEffect(() => {
    if (!freezePosition) {
      setPosition(internalPosition);
    }
  }, [freezePosition, internalPosition]);

  const updateSize = useCallback(() => {
    if (element) {
      const size = {
        width: element.scrollWidth + element.offsetWidth - element.clientWidth,
        height: element.scrollHeight + element.offsetHeight - element.clientHeight,
      };

      setSize(size);
    }
  }, [element]);

  const updatePosition = useCallback(() => {
    if (!parentRef.current) {
      return;
    }
    const element = parentRef.current;

    if (!element.clientWidth) {
      setHidden(true);

      return;
    }
    setHidden(false);

    const parentRect = element.getBoundingClientRect();

    setParentRect(parentRect);

    const distanceTop = parentRect.top - distance;
    const distanceBottom = windowSize.height - parentRect.bottom - distance;

    setPositionTop(alignTop
          && size.height < distanceTop
          && !(alignBottom && size.height < distanceBottom)
          || !alignTop && !alignBottom && distanceTop > distanceBottom);
  }, [alignBottom, alignTop, distance, parentRef, size.height, windowSize.height]);

  const refCallback = useCallback(
    () => {
      updateSize();
      requestAnimationFrame(() => {
        updatePosition();
      });
    },
    [updatePosition, updateSize],
  );

  const onRef = useCombinedRefs(ref, dropdownRef, refCallback);

  useLayoutEffect(() => {
    const onMouseUp = (): void => {
      setFreezePosition(false);
      updatePosition();
    };

    const onMouseDown = (): void => {
      setFreezePosition(true);
    };

    window.addEventListener('mouseup', onMouseUp, false);
    window.addEventListener('mousedown', onMouseDown, false);

    return () => {
      window.removeEventListener('mouseup', onMouseUp, false);
      window.removeEventListener('mousedown', onMouseDown, false);
    };
  }, [updatePosition]);

  useEffect(() => {
    updatePosition();
  }, [
    alignBottom,
    alignTop,
    distance,
    parentRef,
    positionTop,
    size.height,
    size.width,
    updatePosition,
  ]);

  const [sizeObserver] = useState(new MutationObserver(() => updateSize()));
  const [positionObserver] = useState(new MutationObserver(() => updatePosition()));

  useEffect(() => {
    const onClick = (e: MouseEvent): void => {
      const target = e.target as HTMLElement;

      if (
        onClose
          && !parentRef.current?.contains(target)
          && !dropdownRef.current?.contains(target)
          && !target.closest('[data-modal]')
      ) {
        onClose();
      }
    };

    document.addEventListener('click', onClick, true);

    return () => {
      document.removeEventListener('click', onClick, true);
    };
  }, [onClose, parentRef]);

  useLayoutEffect(() => {
    const element = portalRef.current;

    if (element) {
      if (!base) {
        element.dataset.modal = 'true';
      }

      sizeObserver.observe(element, {
        attributes: true,
        childList: true,
        subtree: true,
      });
    }

    return () => {
      sizeObserver.disconnect();
    };
  }, [base, sizeObserver]);

  useLayoutEffect(() => {
    if (!parentRef.current) {
      return undefined;
    }

    positionObserver.observe(parentRef.current, {
      attributes: true,
      childList: true,
      subtree: true,
    });

    return () => {
      positionObserver.disconnect();
    };
  }, [positionObserver, parentRef]);

  const onResize = useCallback((): void => {
    const {
      innerWidth: width, innerHeight: height,
    } = window;

    setWindowSize(size => {
      if (size.width !== width || size.height !== height) {
        return {
          width,
          height,
        };
      }

      return size;
    });
  }, []);

  useLayoutEffect(() => {
    window.addEventListener('resize', onResize);
    window.addEventListener('orientationchange', onResize);
    onResize();

    return () => {
      window.removeEventListener('resize', onResize);
      window.removeEventListener('orientationchange', onResize);
    };
  }, [onResize]);

  useLayoutEffect(() => {
    if (!parentRect || !element) {
      return;
    }
    setInternalPosition(internalPosition => {
      const boundingRect = element.getBoundingClientRect();
      const offsetTop = boundingRect.top - internalPosition.top;
      const offsetLeft = boundingRect.left - internalPosition.left;

      let top;

      if (size.height > windowSize.height) {
        top = -offsetTop;
      } else {
        if ((positionLeft || positionRight) && alignCenter) {
          // Align center of parent
          top = parentRect.top + parentRect.height / 2;
        } else {
          top = positionTop
            ? parentRect.top - size.height - distance
            : parentRect.bottom + distance;
        }
        // Offset so that we are within the viewport
        top = Math.min(
          Math.max(top, -offsetTop),
          windowSize.height - size.height - offsetTop,
        );
      }
      let left;

      if (size.width > windowSize.width) {
        left = -offsetLeft;
      } else {
        if (positionLeft) {
          // Position on the left side of parent
          left = parentRect.left - size.width - distance;
        } else if (positionRight) {
          // Position on the right side of parent
          left = parentRect.right + distance;
        } else if (alignCenter) {
          // Align center of parent
          left = parentRect.left + parentRect.width / 2;
        } else if (alignRight) {
          // Align right inside
          left = parentRect.right - size.width;
        } else {
          // Align left inside
          left = parentRect.left;
        }
        // Offset so that we are within the viewport
        left = Math.min(
          Math.max(left, -offsetLeft),
          windowSize.width - size.width - offsetLeft,
        );
      }
      if (internalPosition.top !== top || internalPosition.left !== left) {
        return {
          top,
          left,
        };
      }

      return internalPosition;
    });
  }, [
    element,
    alignCenter,
    positionRight,
    alignRight,
    distance,
    parentRect,
    positionTop,
    size.height,
    size.width,
    windowSize.width,
    positionLeft,
    windowSize.height,
  ]);

  const dropdownStyle = useMemo(() => [
    css`
      width: max-content;
      max-height: ${windowSize.height}px;
      max-width: ${grow ? windowSize.width : parentRect?.width}px;
      min-width: ${width || !shrink && parentRect?.width || 0}px;
      overflow: hidden;
      overflow-y: auto;
    `,
    parentRect
    && css`
        position: fixed;
        top: ${position.top}px;
        left: ${position.left}px;
      `,
    alignCenter
    && (positionLeft || positionRight
      ? css`
          transform: translateY(-50%);
        `
      : css`
          transform: translateX(-50%);
        `),
    hidden
    && css`
        display: none;
      `,
    {
      background,
      boxShadow: shadow === true ? theme.boxShadow : shadow === false ? 'none' : shadow,
      border:
          border === true ? `1px solid ${theme.cellBorderColor}` : border === false ? '0' : border,
      borderTop:
          borderTop === true
            ? `1px solid ${theme.cellBorderColor}`
            : borderTop === false ? '0' : borderTop,
      borderRight:
      borderRight === true
        ? `1px solid ${theme.cellBorderColor}`
        : borderRight === false ? '0' : borderRight,
      borderBottom:
      borderBottom === true
        ? `1px solid ${theme.cellBorderColor}`
        : borderBottom === false ? '0' : borderBottom,
      borderLeft:
      borderLeft === true
        ? `1px solid ${theme.cellBorderColor}`
        : borderLeft === false ? '0' : borderLeft,
      borderRadius,
      borderTopLeftRadius,
      borderTopRightRadius,
      borderBottomLeftRadius,
      borderBottomRightRadius,
    },
  ], [alignCenter, background, border, borderBottom, borderBottomLeftRadius, borderBottomRightRadius, borderLeft, borderRadius, borderRight, borderTop, borderTopLeftRadius, borderTopRightRadius, grow, hidden, parentRect, position.left, position.top, positionLeft, positionRight, shadow, shrink, theme.boxShadow, theme.cellBorderColor, width, windowSize.height, windowSize.width]);

  return children
    ? (
      <Overlay ref={portalRef}>
        <div
          className={className}
          ref={onRef}
          css={dropdownStyle}
        >
          {children}
        </div>
      </Overlay>
    )
    : null;
});
