import {css} from '@emotion/react';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import {faChevronRight} from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {
  Block,
  Dropdown,
  renderItem,
  useRequiredContext,
} from '@snapper/core';

import {
  FC,
  useCallback,
  useRef,
} from 'react';

import {
  menuDefinitions,
  MenuItem,
  TopMenuItem,
} from '../definitions/menu-items';

import {Context} from '../menu-context';

export const DropdownMenu: FC<MenuItem<'menu'>> = ({
  index,
  label,
  active,
  items,
}) => {
  const context = useRequiredContext(Context);

  const {
    closeMenu,
    activeMenu,
    setActiveMenu,
    className,
    isMobile,
  } = context;

  const onClick = useCallback(() => setActiveMenu(active => active?.items === items ? null : {
    index,
    items,
    title: label,
  }), [index, items, label, setActiveMenu]);
  const ref = useRef(null);

  return isMobile ? (
    <div
      className="menu-item"
      onClick={onClick}
    >
      {label}
      <div className="more">
        <FontAwesomeIcon
          icon={faChevronRight}
        />
      </div>

    </div>
  ) : (
    <>
      <TopMenuItem
        ref={ref}
        padding="8px 10px"
        borderRadius="5px"
        color={context.textColor ? context.textColor : context.darkColor ? '#FFF' : '#000'}
        nowrap
        active={activeMenu?.items === items || active}
        onClick={onClick}
      >
        {label}
        <FontAwesomeIcon
          css={css`
          margin-left: 0.8em;
        `}
          icon={faCaretDown}
        />
      </TopMenuItem>
      {activeMenu?.index === index && (
        <Dropdown
          onClose={closeMenu}
          parentRef={ref}
          className={className + '-drop'}
          alignBottom
          distance={12}
          grow
          shadow
          base
        >
          <Block
            background="#fff"
            border
            borderTop={false}
            padding="40px"
            paddingBottom="10px"
            row={activeMenu?.items.length !== 1}
            column={activeMenu?.items.length === 1}
            width="100vw"
            maxWidth="1200px"
            marginBetween="20px"
            shadow
          >
            {activeMenu?.items && (
                <>
                  {activeMenu?.items[0].length && (
                      <>
                        { activeMenu?.items.length === 1 && (
                            <>
                              {activeMenu?.items[0].map((item) => renderItem(item, menuDefinitions, context))}
                            </>
                        ) || (
                            <>
                              {activeMenu?.items.map((subItems, count) => (
                                  <Block
                                      width={95/activeMenu?.items.length + '%'}
                                      column
                                  >
                                    {subItems.map((item) => renderItem(item, menuDefinitions, context))}
                                  </Block>
                              ))}
                            </>
                        )}
                      </>
                  )}
                </>
            )}
          </Block>
        </Dropdown>
      )}
    </>
  );
};
