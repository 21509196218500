import {
  forwardRef,
  FC,
  useState,
  useCallback,
  useEffect,
  ReactNode,
  RefAttributes,
  useMemo,
} from 'react';
import randomColor from 'randomcolor';
import {
  darken, lighten,
} from 'polished';
import {Property} from 'csstype';
import {
  Block, Label,
} from './Layout';
import {useTheme} from '../utils/hooks';
import {
  ProgressCircle, ProgressCircleProps,
} from './ProgressCircle';

export interface UserAvatarProps {
  className?: string;
  size: number;
  name?: string;
  src?: string;
  backgroundColor?: Property.BackgroundColor;
  primaryColor?: ProgressCircleProps['primaryColor'];
  secondaryColor?: ProgressCircleProps['secondaryColor'];
  rotation?: ProgressCircleProps['rotation'];
  strokeWidth?: ProgressCircleProps['strokeWidth'];
  strokeLinecap?: ProgressCircleProps['strokeLinecap'];
  value?: ProgressCircleProps['value'];
  ease?: ProgressCircleProps['ease'];
  duration?: ProgressCircleProps['duration'];
  formatLabel?: ProgressCircleProps['formatLabel'];
  children?: ReactNode;
  labelScale?: number;
}

export const UserAvatar = forwardRef<HTMLDivElement, UserAvatarProps>(({
  className,
  size,
  name,
  src,
  backgroundColor,
  primaryColor,
  secondaryColor,
  rotation,
  strokeWidth = 1,
  strokeLinecap,
  value,
  ease,
  duration,
  formatLabel,
  labelScale = 1,
  children,
}, ref) => {
  const {
    lightColor,
    avatarUseRandomColor,
    avatarBackgroundColor,
    avatarRandomColorOptions,
  } = useTheme();

  const hasSrc = !!src;
  const [hasImage, setHasImage] = useState<boolean>(hasSrc);

  useEffect(() => {
    setHasImage(hasSrc);
  }, [hasSrc]);

  const avatarColor = useMemo(
    () => backgroundColor === undefined && !hasImage ? name
  && avatarUseRandomColor
  && randomColor({
    ...avatarRandomColorOptions,
    seed: name,
  })
    || avatarBackgroundColor : backgroundColor,
    [avatarBackgroundColor, avatarRandomColorOptions, avatarUseRandomColor, backgroundColor, hasImage, name],
  );
  const [initials, setInitials] = useState<string[]>();

  useEffect(() => {
    setInitials(name ? name.split(' ').map(namePart => namePart.charAt(0)) : []);
  }, [name]);

  const onError = useCallback(() => {
    setHasImage(false);
  }, [setHasImage]);

  return (
    <div
      className={className}
      ref={ref}
      css={{
        position: 'relative',
        overflow: 'visible',
        width: size,
        height: size,
      }}
    >
      {value != null && (
        <div
          css={{
            position: 'absolute',
            inset: -strokeWidth,
          }}
        >
          <ProgressCircle
            size={size + strokeWidth * 2}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            rotation={rotation}
            strokeWidth={strokeWidth}
            strokeLinecap={strokeLinecap}
            value={value}
            ease={ease}
            duration={duration}
            formatLabel={formatLabel}
          />
        </div>
      )}
      <Block
        as={hasImage ? 'img' : 'div'}
        onError={onError}
        src={src}
        width={ size+'px'}
        height="100%"
        borderRadius="50%"
        overflow="hidden"
        objectFit="cover"
        background={avatarColor}
        color={avatarColor ? (avatarRandomColorOptions?.luminosity === 'light' ? darken : lighten)(0.4, avatarColor) : lightColor}
        justify="center"
        css={{fontSize: size, objectFit: 'cover'}}
      >
        {!hasImage
          ? children
            ?? (!!initials?.length && (
              <Label
                bold
                size={`${1 / Math.min(Math.max(initials.length, 2), 4) * labelScale}em`}
                lineHeight="0"
                nowrap
                marginTop="0.1em"
              >
                {initials.join('')}
              </Label>
            ))
          : null}
      </Block>
    </div>
  );
}) as FC<UserAvatarProps & RefAttributes<HTMLDivElement>>;
