import {
  useCallback,
  useEffect,
  useState,
  useRef,
  Ref,
  RefObject,
  DependencyList,
  RefCallback,
  useMemo,
  Context, useContext, ElementRef,
  Dispatch, SetStateAction,
} from 'react';
import Fuse from 'fuse.js';
import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  CancelTokenSource,
} from 'axios';
import {useTheme as useEmotionTheme} from '@emotion/react';
import {Theme} from '../types/theme';

/**
 * Listen for click and touch events that are not part of refs
 */
export const useOnClickOutside = (
  refs:
    (RefObject<HTMLElement | undefined> | undefined)[]
    | RefObject<HTMLElement | undefined>
    | undefined,
  callback: (e: MouseEvent | TouchEvent) => void,
): void => {
  const listener = useCallback(
    event => {
      if (event.defaultPrevented) {
        return;
      }
      const forAny = (fn: (element: HTMLElement) => boolean): boolean =>
        Boolean(Array.isArray(refs)
          ? refs.reduce(
            (bool, ref) => !!(bool || ref?.current && fn(ref.current)),
            false,
          )
          : refs?.current && fn(refs.current));

      if (!forAny(current => current.contains(event.target))) {
        callback(event);
      }
    },
    [callback, refs],
  );

  useEffect(() => {
    document.addEventListener('mousedown', listener, false);
    document.addEventListener('touchstart', listener, false);

    return () => {
      document.removeEventListener('mousedown', listener, false);
      document.removeEventListener('touchstart', listener, false);
    };
  }, [listener]);
};

/**
 * Creates a RefCallback to set the value of refs passed as parameters.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useCombinedRefs = <T extends ElementRef<any>>(...refs: (Ref<T> | undefined)[]): RefCallback<T> => {
  const refCallback = useCallback<(
    current: T) => void>(
    current => {
      refs.forEach(ref => {
        if (!ref) {
          return;
        }

        if (typeof ref === 'function') {
          ref(current);
        } else {
          Object.assign(ref, {current});
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    refs,
    );

  return refCallback;
};

/**
 * Filter a list of objects using Fuse.js fuzzy search.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useFuzzySearch = <T extends Record<string, any>>(
  items: T[],
  term?: string,
  options?: Fuse.IFuseOptions<T>,
): Fuse.FuseResult<T>[] => {
  const [fuse, setFuse] = useState<Fuse<T>>();
  const [filteredItems, setFilteredItems] = useState<Fuse.FuseResult<T>[]>([]);

  useEffect(() => {
    setFuse(new Fuse(items, options ?? {}));
  }, [items, options]);

  useEffect(() => {
    if (term && fuse) {
      setFilteredItems(fuse.search(term));
    } else {
      setFilteredItems(items.map((item, refIndex) => ({
        item,
        refIndex,
      })));
    }
  }, [term, fuse, items]);

  return filteredItems;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface AxiosFetchResponse<T = any, P = any> {
  response: AxiosResponse<T> | null;
  data: T | null;
  loading: boolean;
  error: AxiosError<T> | null;
  fetch: (params?: P) => Promise<AxiosResponse<T> | void>;
  reset: () => void;
}

/**
 * Fetch data using axios.
 * ```ts
  const {data, loading, error, fetch} = useAxiosFetch({
    method: 'GET',
    url: 'https://...',
    withCredentials: true,
  });

  useEffect(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    console.log(data); // Response data
  }, [data]);
  ```
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useAxiosFetch = <T = any, P = any>(
  config: (Omit<AxiosRequestConfig, 'cancelToken'> & ({data?: P} | {params?: P}) & {
    fetch?: boolean;
  }) | ((params?: P) => Omit<AxiosRequestConfig, 'cancelToken'>),
  deps: DependencyList,
): AxiosFetchResponse<T, P> => {
  const [response, setResponse] = useState<AxiosResponse<T> | null>(null);
  const [error, setError] = useState<AxiosError | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const unmounted = useRef<boolean>(false);
  const source = useRef<CancelTokenSource>();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const axiosConfig = useMemo(() => config, deps); // Get the updated config only if dependency array changes.

  const cancelMsg = 'Operation canceled by the user.';

  const reset = useCallback(() => {
    if (source.current) {
      source.current.cancel(cancelMsg);
    }
    setLoading(false);
    setError(null);
    setResponse(null);
  }, [setError, setLoading, setResponse, source]);

  const fetch = useCallback(async (params?: P) => {
    setLoading(true);
    if (source.current) {
      source.current.cancel(cancelMsg);
    }
    const tokenSource = axios.CancelToken.source();

    source.current = tokenSource;
    const response = await axios
      .request({
        ...typeof axiosConfig === 'function' ? axiosConfig(params) : axiosConfig,
        cancelToken: tokenSource.token,
      })
      .catch(error => {
        if (!unmounted.current && error.message !== cancelMsg) {
          setError(error);
          setLoading(false);
        }
      });

    if (response) {
      setResponse(response);
      setError(null);
      setLoading(false);
    }

    return response;
  }, [axiosConfig]);

  useEffect(
    () => () => {
      unmounted.current = true;
      if (source.current) {
        source.current.cancel(cancelMsg);
      }
    },
    [unmounted, source],
  );

  useEffect(
    () => {
      if (typeof axiosConfig === 'object' && axiosConfig.fetch) {
        fetch();
      }
    },
    [axiosConfig, fetch],
  );

  // Static object to prevent render loop when used as a dependency for hooks. (eslint issue with functions)
  const output: Partial<AxiosFetchResponse<T, P>> = useMemo(() => ({}), []);

  Object.assign(output, {
    response: response ?? null,
    data: response?.data ?? null,
    loading,
    error,
    fetch,
    reset,
  });

  return output as AxiosFetchResponse<T, P>;
};

export const useRequiredContext = <T>(context: Context<T>, error = `Context "${context.displayName}" is null or undefined`): NonNullable<T> => {
  const value = useContext<T>(context);

  if (value == null) {
    throw new Error(error);
  }

  return value as NonNullable<T>;
};

export const useTheme = (override: Partial<Theme> = {}): Theme => ({
  ...useEmotionTheme() as Theme,
  ...override,
});

export const useInputState = <T>(value: T, onChange?: (value: Exclude<T, undefined>) => void, constraint?: (value: T, prevValue: T) => T): [value: T, setValue: Dispatch<SetStateAction<T>>, changed: boolean] => {
  const [internalValue, setInternalValue] = useState(value);
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    setInternalValue(value);
    setChanged(false);
  }, [value]);

  const setValue = useCallback(value => {
    setInternalValue(constraint ? prevValue => constraint(value, prevValue) : value);
    setChanged(true);
  }, [constraint]);

  useEffect(() => {
    if (changed && onChange && internalValue !== undefined) {
      onChange(internalValue as Exclude<T, undefined>);
    }
    setChanged(false);
  }, [changed, onChange, internalValue]);

  return [internalValue, setValue, changed];
};
