import {ReactElement} from 'react';
import {Property} from 'csstype';
import {
  Block,
  Item,
  ItemDefinitions,
  Label,
  Separator,
} from '@snapper/core';
import styled from '@emotion/styled';
import {css} from '@emotion/react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import * as styles from '../styles/input-styles';
import {
  Attach,
  MenuContext,
} from '../types/menu';
import {DropdownMenu} from '../items/DropdownMenu';

type FnOrValue<T, V = []> = T | ((...args: V[]) => T);
const fnOrValue = <T, V>(value: FnOrValue<T, V>, ...args: V[]): T => typeof value === 'function' ? (value as (...args: V[]) => T)(...args) : value;


export interface ItemTypes {
  menu: {
    label: string;
    items: Array<MenuItem[]>;
    attach?: Attach[];
  };
  menuItem: {
    label: string;
    active?: boolean;
    href?: string;
  };
  button: {
    label: string;
    onClick: (context: MenuContext) => void;
    attach?: Attach[];
  };
  simpleButton: {
    label: string;
    icon?: IconProp;
    attach?: Attach[];
    onClick: (context: MenuContext) => void;
  };
  separator: {
    attach?: Attach[];
    margin?: string[];
  };
  textSeparator: {
    label?: string;
    attach?: Attach[];
  };
  title: {
    label: string;
    onClick?: () => void;
    href?: string;
    attach?: Attach[];
  };
  label: {
    label: string;
    bold?: boolean;
    attach?: Attach[];
    onClick?: () => void;
    href?: string;
    size?: Property.FontSize;
  };
  featureLink: {
    label: string;
    subLabel?: string;
    icon?: IconProp;
    onClick?: () => void;
    href?: string;
    attach?: Attach[];
  };
  custom: {
    render: (context: MenuContext) => ReactElement;
  };
}

export type MenuItem<T = keyof ItemTypes> = Item<ItemTypes, T> & {index?: number; active?: boolean; attach?: Attach[]};

export const TopMenuItem = styled(Block)<{active?: boolean}>(
  css`
    cursor: pointer;
    transition: background 0.2s ease-out;

    :hover {
      background: rgba(0, 0, 0, 0.2);
    }
  `,
  ({active}) => active && css`
    background: rgba(0, 0, 0, 0.2);
  `,
);

export const menuDefinitions: ItemDefinitions<ItemTypes, MenuContext> = {
  menu: {component: DropdownMenu},
  menuItem: {
      render: ({
          label,
          active,
          href,
      }, {
          history,
          darkColor,
          textColor,
          closeMenu,
          isMobile,
      } ) => {
          const clickable = href;

          return isMobile && (
              <div
                  className="menu-link"
                  onClick={
                      clickable
                          ? () => {
                              if (history && href) {
                                  history.push(href);
                              } else if (href) {
                                  window.location.href = href;
                              }
                              closeMenu();
                          }
                          : undefined
                    }
              >
                  {label}
              </div>
          ) || (
              <div>
                  <TopMenuItem
                      padding="8px 10px"
                      onClick={
                          clickable
                          ? () => {
                              if (history && href) {
                                  history.push(href);
                              } else if (href) {
                                  window.location.href = href;
                              }
                          }
                          : undefined
                      }
                      borderRadius="5px"
                      active={active}
                      color={textColor ? textColor : darkColor && '#FFF' || '#000'}
                      nowrap
                  >
                      {label}
                  </TopMenuItem>
              </div>
          );
      }
  },
  button: {
    render: ({
      label,
      onClick,
    }, context) => context.isMobile
      ? <div>btn</div>
      : (
        <TopMenuItem
          color="#fff"
          nowrap
          onClick={() => onClick(context)}
        >
          {label}
        </TopMenuItem>
      ),
  },
  separator: {
    render: ({margin}) => (
      <Separator
        margin={margin}
      />
    ),
  },
  textSeparator: {
    render: ({label}) =>
      (
        <Label
          bold
          style={{
            paddingBottom: 10,
          }}
        >
          {label}
        </Label>
      ),
  },
  title: {
    render: (
      {
        label,
        href,
        onClick,
      },
      {
        history,
        closeMenu,
        isMobile,
      },
    ) => {
      const clickable = onClick || href;

      return (
        <Label
          bold
          size="1.5em"
          onClick={clickable
            ? () => {
              closeMenu();
              if (onClick) {
                onClick();
              }else if (history && href) {
                history.push(href);
              } else if (href) {
                  window.location.href = href;
              }
            }
            : undefined}
          css={[
            css`
              color: #000;
            `,
            isMobile && css`
              padding-top: 20px;
            `,
            clickable && css`
              cursor: pointer;
              :hover, :focus {
                color: #000;

                span:first-child {
                  text-decoration: underline;
                }
              }
            `,
          ]}
        >
          {label}
        </Label>
      );
    },
  },
  simpleButton: {
    render: ({
      label,
      icon,
      onClick,
    }, context) =>
      context.isMobile ? (
        <div
          onClick={() => {
            onClick(context);
          }}
          className="menu-item"
        >
          {icon && (
            <FontAwesomeIcon
              style={{marginRight: 10}}
              icon={icon}
            />
          )}
          {label}
        </div>
      ) : (
        <styles.SimpleButton
          onClick={() => {
            onClick(context);
          }}
        >
          {icon && (
            <FontAwesomeIcon
              style={{marginRight: 5}}
              icon={icon}
            />
          )}
          {label}
        </styles.SimpleButton>
      ),
  },

  label: {
    render: ({
      label,
      bold,
      size,
      onClick,
      href,
    }, {
      history,
      closeMenu,
      isMobile,
    }) => {
      const clickable = onClick || href;

      return (
        <Label
          bold={bold}
          size={size}
          onClick={clickable
            ? () => {
              closeMenu();
              if (onClick) {
                onClick();
              }else if (history && href) {
                history.push(href);
              } else if (href) {
                  window.location.href = href;
              }
            }
            : undefined}
          css={[
            css`
              color: #000;
              margin-bottom: 4px;
            `,
            isMobile && css`
              width: 100%;
            `,
            clickable && css`
              cursor: pointer;
              :hover, :focus {
                color: #000;
                text-decoration: underline;

                span:first-child {
                  text-decoration: underline;
                }
              }
            `,
          ]}
        >
          {label}
        </Label>
      );
    },
  },
  featureLink: {
    render: ({
      label,
      subLabel,
      icon,
      onClick,
      href,
    }, {
      history,
      isMobile,
      closeMenu,
      theme,
    }) => {
      const clickable = onClick || href;
      const iconValue = icon && fnOrValue(icon);
      console.log('theme', theme)
      return isMobile ? (
        <div
          className="menu-link"
          onClick={clickable
            ? () => {
              closeMenu();
              if (onClick) {
                onClick();
              } else if (history && href) {
                history.push(href);
              } else if (href) {
                  window.location.href = href;
              }
            }
            : undefined}
        >
          {label}
        </div>
      ) : (
          <div
            style={{
                float: 'left',
                width: '100%'
            }}
          >
              {iconValue && (
                  <Block
                    style={{
                        float: 'left',
                        marginRight: 15,
                        width: 40, height: 40,
                        borderRadius: 40,
                        justifyContent: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        background: theme?.scheme?.primary.color100
                    }}
                  >
                      <FontAwesomeIcon
                        icon={iconValue}
                        color={theme?.scheme?.primary.color700}
                      />
                  </Block>
              )}

              <Block
                column
              >
                <Label
                    size={'14px'}
                    style={{marginBottom: 30,}}
                    onClick={clickable
                    ? () => {
                      closeMenu();
                      if (onClick) {
                        onClick();
                      }else if (history && href) {
                        history.push(href);
                      } else if (href) {
                          window.location.href = href;
                      }
                    }
                    : undefined}
                  css={[
                    css`
                      color: #101828;
                    `,
                    clickable && css`
                      cursor: pointer;
                      :hover, :focus {
                        color: #000;
        
                        span:first-child {
                          text-decoration: underline;
                        }
                      }
                    `,
                  ]}
                >
                  <Label
                    bold
                    size="1.5em"
                  >
                    {label}
                  </Label>
                  <br />
                  <Label
                    marginTop="0.5em"
                    color={'#667085'}
                    size="1em"
                  >
                    {subLabel}
                  </Label>
                </Label>
              </Block>
          </div>
      );
    },
  },
  custom: {render: ({render}, context) => render(context)},
};
