import {
  forwardRef,
  HTMLAttributes,
  ElementType,
  KeyboardEvent,
  MouseEvent,
  EventHandler,
  useMemo,
  ComponentPropsWithoutRef,
  ReactElement,
  Ref,
  ElementRef,
  FC,
} from 'react';
import {
  Properties, Property,
} from 'csstype';
import {css} from '@emotion/react';
import {CSSInterpolation} from '@emotion/serialize';
import styled from '@emotion/styled';
import {useTheme} from '../utils/hooks';
import {Theme} from '../types/theme';

type AsProp<T extends ElementType> = {
  as?: T;
};

type AllowAnyProps = {
  [key: string]: unknown;
};

export type Rect = {
  top?: number | Property.Top;
  right?: number | Property.Right;
  bottom?: number | Property.Right;
  left?: number | Property.Right;
  width?: number | Property.Right;
  height?: number | Property.Right;
};

type Justify =
  | 'top-left'
  | 'top'
  | 'top-right'
  | 'left'
  | 'center'
  | 'right'
  | 'bottom-left'
  | 'bottom'
  | 'bottom-right';

export interface BlockProps<T extends ElementType = 'div'> extends AsProp<T>, AllowAnyProps {
  rect?: Rect;
  inset?: Property.Inset | boolean;
  row?: boolean;
  column?: boolean;
  justify?: Justify;
  fixed?: boolean;
  relative?: boolean;
  grow?: boolean;
  shrink?: boolean;
  wrap?: boolean;
  nowrap?: boolean;
  flexBasis?: Property.FlexBasis;
  color?: Property.Color;
  background?: Property.Background;
  padding?: Property.Padding;
  paddingTop?: Property.PaddingTop;
  paddingRight?: Property.PaddingRight;
  paddingBottom?: Property.PaddingBottom;
  paddingLeft?: Property.PaddingLeft;
  margin?: Property.Margin;
  marginTop?: Property.MarginTop;
  marginRight?: Property.MarginRight;
  marginBottom?: Property.MarginBottom;
  marginLeft?: Property.MarginLeft;
  marginBetween?:
    | Property.MarginRight
    | Property.MarginBottom;
  visibility?: Property.Visibility;
  overflow?: Property.Overflow;
  overflowX?: Property.OverflowX;
  overflowY?: Property.OverflowY;
  ripple?: Property.BackgroundColor;
  rippleIn?: Property.BackgroundColor;
  rippleOut?: Property.BackgroundColor;
  /** This is the target scale for the ripple effect. Should be the ratio of height to width and greater than 1.0, and then some to account for the border radius. */
  rippleSize?: number;
  border?: Property.Border | boolean;
  borderTop?: Property.BorderTop | boolean;
  borderRight?: Property.BorderRight | boolean;
  borderBottom?: Property.BorderBottom | boolean;
  borderLeft?: Property.BorderLeft | boolean;
  borderRadius?: Property.BorderRadius;
  borderTopLeftRadius?: Property.BorderTopLeftRadius;
  borderTopRightRadius?: Property.BorderTopRightRadius;
  borderBottomLeftRadius?: Property.BorderBottomLeftRadius;
  borderBottomRightRadius?: Property.BorderBottomRightRadius;
  hoverBorder?: Property.Border | boolean;
  hoverBorderTop?: Property.BorderTop | boolean;
  hoverBorderRight?: Property.BorderRight | boolean;
  hoverBorderBottom?: Property.BorderBottom | boolean;
  hoverBorderLeft?: Property.BorderLeft | boolean;
  hoverBorderRadius?: Property.BorderRadius;
  hoverBorderTopLeftRadius?: Property.BorderTopLeftRadius;
  hoverBorderTopRightRadius?: Property.BorderTopRightRadius;
  hoverBorderBottomLeftRadius?: Property.BorderBottomLeftRadius;
  hoverBorderBottomRightRadius?: Property.BorderBottomRightRadius;
  hoverBackground?: Property.Background;
  hoverColor?: Property.Color;
  hoverDuration?: Property.TransitionDuration;
  pointer?: boolean;
  lineHeight?: Property.LineHeight;
  minWidth?: number | Property.MinWidth;
  minHeight?: number | Property.MinHeight;
  width?: number | Property.Width;
  height?: number | Property.Height;
  maxWidth?: number | Property.MaxWidth;
  maxHeight?: number | Property.MaxHeight;
  zIndex?: Property.ZIndex;
  shadow?: Property.BoxShadow | boolean;
  onClick?: EventHandler<MouseEvent | KeyboardEvent>;
}

const justifyStyles: {[key in Justify]: Properties} = {
  'top-left': {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  top: {
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  'top-right': {
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  left: {
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  right: {
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  'bottom-left': {
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },
  bottom: {
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  'bottom-right': {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
};

// Compensate for justify and align being rotated in flex columns.
const justifyColumnMapping: {[key in Justify]: Justify} = {
  'top-left': 'top-left',
  top: 'left',
  'top-right': 'bottom-left',
  left: 'top',
  center: 'center',
  right: 'bottom',
  'bottom-left': 'top-right',
  bottom: 'right',
  'bottom-right': 'bottom-right',
};

export interface SeparatorProps { margin?: string[]; theme?: Theme; background?: Property.Background};

/**
 * Draws a separator line inside a row or column block. Takes margin
 */
export const Separator = styled.div<SeparatorProps>(({
  theme = null,
  margin = ['0', '0', '1em', '0'],
  background = theme?.cellBorderColor,
}) => css`
    flex: 0 0 auto;
    width: 100%;
    height: 1px;
    margin: ${margin.join(' ')};
    background: ${background};
    align-self: stretch;
  `);

const numberToPx = <T, >(value: number | T): string | T =>
  typeof value === 'number' ? `${value}px` : value;

/**
 * Base component that uses flexbox to make rows and columns with readable JSX code.
 * Most essential styles are exposed as props, additional styles can be set using the style prop.
 * Handles role, tabIndex and onKeyPress props for you to act as a button if onClick is set.
 */
export const Block = forwardRef(<T extends ElementType = 'div'>(
  {
    as,
    rect,
    onClick,
    onKeyPress,
    role,
    tabIndex,
    inset,
    row,
    column,
    justify,
    fixed,
    relative,
    grow = false,
    shrink = false,
    flexBasis = 'auto',
    wrap,
    nowrap,
    color,
    background,
    padding,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    margin,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    marginBetween,
    visibility,
    overflow,
    overflowX,
    overflowY,
    ripple,
    rippleIn,
    rippleOut,
    rippleSize = 1.2,
    border,
    borderTop,
    borderRight,
    borderBottom,
    borderLeft,
    borderRadius,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderBottomLeftRadius,
    borderBottomRightRadius,
    hoverBorder,
    hoverBorderTop,
    hoverBorderRight,
    hoverBorderBottom,
    hoverBorderLeft,
    hoverBorderRadius,
    hoverBorderTopLeftRadius,
    hoverBorderTopRightRadius,
    hoverBorderBottomLeftRadius,
    hoverBorderBottomRightRadius,
    hoverScale,
    hoverBackground,
    hoverColor,
    hoverDuration,
    pointer,
    lineHeight,
    minWidth,
    minHeight,
    width,
    height,
    maxWidth,
    maxHeight,
    zIndex,
    shadow,
    children,
    ...props
  }: BlockProps<T> & Partial<ComponentPropsWithoutRef<T>>,
  ref: Ref<ElementRef<T>>,
) => {
  const theme = useTheme();

  const justifyPosition = !!(row || column || justify || wrap);

  const hasRipple = ripple || rippleIn || rippleOut;

  const transition = useMemo<Property.Transition>(() => [
    hoverBackground && 'background',
    hoverColor && 'color',
    (hoverBorder
      || hoverBorderTop
      || hoverBorderRight
      || hoverBorderBottom
      || hoverBorderLeft
    ) && 'border',
    (hoverBorderRadius
      || hoverBorderTopLeftRadius
      || hoverBorderTopRightRadius
      || hoverBorderBottomLeftRadius
      || hoverBorderBottomRightRadius
    ) && 'border-radius',
    hoverScale && 'transform',
  ].filter(Boolean).map(x => `${x} ${hoverDuration ?? theme.hoverDuration} ease-out`)
    .join(', '), [hoverBackground, hoverBorder, hoverBorderBottom, hoverBorderBottomLeftRadius, hoverBorderBottomRightRadius, hoverBorderLeft, hoverBorderRadius, hoverBorderRight, hoverBorderTop, hoverBorderTopLeftRadius, hoverBorderTopRightRadius, hoverColor, hoverScale, hoverDuration, theme.hoverDuration]);
  const layoutStyle = {
    borderCollapse: 'collapse',
    boxSizing: 'border-box',
    flexGrow: grow ? 1 : 0,
    flexShrink: shrink ? 1 : 0,
    flexBasis,
    position: fixed ? 'fixed' : inset ? 'absolute' : justifyPosition || hasRipple || relative ? 'relative' : undefined,
    whiteSpace: nowrap ? 'nowrap' : undefined,
    color,
    inset: inset === true ? '0' : inset === false ? 'auto' : inset,
    background,
    padding,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    margin,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    visibility,
    overflow: hasRipple ? 'hidden' : overflow,
    overflowX,
    overflowY,
    zIndex,
    boxShadow: shadow === true ? theme.boxShadow : shadow === false ? 'none' : shadow,
    border:
        border === true ? `1px solid ${theme.cellBorderColor}` : border === false ? '0' : border,
    borderTop:
        borderTop === true
          ? `1px solid ${theme.cellBorderColor}`
          : borderTop === false ? '0' : borderTop,
    borderRight:
        borderRight === true
          ? `1px solid ${theme.cellBorderColor}`
          : borderRight === false ? '0' : borderRight,
    borderBottom:
        borderBottom === true
          ? `1px solid ${theme.cellBorderColor}`
          : borderBottom === false ? '0' : borderBottom,
    borderLeft:
        borderLeft === true
          ? `1px solid ${theme.cellBorderColor}`
          : borderLeft === false ? '0' : borderLeft,
    borderRadius,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderBottomLeftRadius,
    borderBottomRightRadius,
    lineHeight,
    minWidth: numberToPx(minWidth),
    minHeight: numberToPx(minHeight),
    width: numberToPx(width),
    height: numberToPx(height),
    maxWidth: numberToPx(maxWidth),
    maxHeight: numberToPx(maxHeight),
    transition,
    ':hover, :focus, :active': {
      border:
            hoverBorder === true
              ? `1px solid ${theme.cellBorderColor}`
              : hoverBorder === false ? '0' : hoverBorder,
      borderTop:
            hoverBorderTop === true
              ? `1px solid ${theme.cellBorderColor}`
              : hoverBorderTop === false ? '0' : hoverBorderTop,
      borderRight:
            hoverBorderRight === true
              ? `1px solid ${theme.cellBorderColor}`
              : hoverBorderRight === false ? '0' : hoverBorderRight,
      borderBottom:
            hoverBorderBottom === true
              ? `1px solid ${theme.cellBorderColor}`
              : hoverBorderBottom === false ? '0' : hoverBorderBottom,
      borderLeft:
            hoverBorderLeft === true
              ? `1px solid ${theme.cellBorderColor}`
              : hoverBorderLeft === false ? '0' : hoverBorderLeft,
      borderRadius: hoverBorderRadius,
      borderTopLeftRadius: hoverBorderTopLeftRadius,
      borderTopRightRadius: hoverBorderTopRightRadius,
      borderBottomLeftRadius: hoverBorderBottomLeftRadius,
      borderBottomRightRadius: hoverBorderBottomRightRadius,
      background: hoverBackground,
      color: hoverColor,
      transform: `scale(${hoverScale})`,
      cursor: pointer !== false && onClick || pointer ? 'pointer' : undefined,
    },
  };

  const flexStyle = useMemo<CSSInterpolation>(() => {
    if (justifyPosition) {
      return {
        display: 'flex',
        flexWrap: wrap ? 'wrap' : 'nowrap',
        flexDirection: column ? 'column' : 'row',
        ...justify
          ? justifyStyles[column ? justifyColumnMapping[justify] : justify]
          : {},
      };
    }

    return {};
  }, [column, justify, justifyPosition, wrap]);

  const rectStyle = useMemo<CSSInterpolation>(() => {
    if (rect) {
      const style: Record<string, unknown> = {};

      Object.entries(rect).forEach(([key, value = undefined]) => {
        if (value !== undefined) {
          style[key] = numberToPx(value);
        }
      });
      style.position = 'fixed';

      return style as CSSInterpolation;
    }

    return {};
  }, [rect]);

  const Tag = (as ?? 'div') as ElementType;

  return (
      // @ts-ignore */}
      <Tag
      css={[
        layoutStyle,
        hasRipple && {
          '::before': {
            content: "''",
            width: '100%',
            transition: !rippleIn ? `transform ${hoverDuration ?? theme.hoverDuration} ease-out` : 'none',
            position: 'absolute',
            top: '50%',
            left: 0,
            paddingBottom: '100%',
            borderRadius: '50%',
            background: hasRipple,
            transform: 'translateY(-50%) scale(0)',
            opacity: !rippleIn ? 1 : 0,
          },
          ':hover, :focus, :active': {
            '::before': {
              transform: `translateY(-50%) scale(${rippleSize})`,
              transition: !rippleOut ? `transform ${hoverDuration ?? theme.hoverDuration} ease-out` : 'none',
              opacity: 1,
            },
          },
        },
        flexStyle,
        rectStyle,
        row
            && css`
              & > ${Separator} {
                width: 1px;
                height: auto;
              }
            `,
        column
            && css`
              & > ${Separator} {
                height: 1px;
                width: auto;
              }
            `,
        marginBetween
            && css`
              & > * {
                ${row
              ? `margin-right: ${marginBetween}`
              : `margin-bottom: ${marginBetween}`};

                &:last-child {
                  ${row ? 'margin-right: 0' : 'margin-bottom: 0'};
                }
              }
            `,

      ]}
      onClick={onClick}
        /**
         *  Set accessibility props automatically if onClick is set, unless overrided
         */
      role={
        role !== undefined ? role : onClick ? 'button' : undefined
      }
      onKeyPress={
        onKeyPress
          || onClick
            && ((e: KeyboardEvent) => e.key === 'Enter' ? onClick(e) : true)
      }
      tabIndex={
        tabIndex !== undefined ? tabIndex : onClick ? 0 : undefined
      }
      {...props}
      ref={ref}
    >
      {hasRipple ? (
        <span
          css={{
            position: 'relative',
            display: 'inline-block',
          }}
        >
          {children}
        </span>
      ) : children}
    </Tag>
  );
}) as <T extends ElementType = 'div'>(props: BlockProps<T> & Partial<ComponentPropsWithoutRef<T>>) => ReactElement;

export interface LabelProps
  extends Omit<HTMLAttributes<HTMLSpanElement | HTMLAnchorElement>, 'onClick'>, AllowAnyProps {
  inline?: boolean;
  relative?: boolean;
  nowrap?: boolean;
  color?: Property.Color;
  background?: Property.Background;
  margin?: Property.Margin;
  marginTop?: Property.MarginTop;
  marginRight?: Property.MarginRight;
  marginBottom?: Property.MarginBottom;
  marginLeft?: Property.MarginLeft;
  padding?: Property.Padding;
  paddingTop?: Property.PaddingTop;
  paddingRight?: Property.PaddingRight;
  paddingBottom?: Property.PaddingBottom;
  paddingLeft?: Property.PaddingLeft;
  visibility?: Property.Visibility;
  lineHeight?: Property.LineHeight;
  link?: boolean;
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  weight?: Property.FontWeight;
  size?: Property.FontSize;
  fontVariant?: Property.FontVariant;
  fontStyle?: Property.FontStyle;
  align?: Property.TextAlign;
  textDecoration?: Property.TextDecoration;
  href?: string;
  onClick?: EventHandler<MouseEvent | KeyboardEvent>;
}

export const Label = forwardRef<
  HTMLSpanElement & HTMLAnchorElement,
  LabelProps
>((
  {
    inline,
    relative,
    nowrap,
    color,
    background,
    margin,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    padding,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    visibility,
    lineHeight,
    link,
    bold,
    italic,
    underline,
    weight,
    size,
    fontVariant,
    fontStyle,
    align,
    textDecoration,
    onClick,
    href,
    role,
    onKeyPress,
    tabIndex,
    children,
    ...props
  },
  ref,
) => {
  const theme = useTheme();
  const Tag = href ? 'a' : 'span';

  return (
    <Tag
      ref={ref}
      css={[
        {
          position: relative ? 'relative' : undefined,
          whiteSpace: nowrap ? 'nowrap' : undefined,
          display: inline ? 'inline' : 'inline-block',
          color,
          background,
          margin,
          marginTop,
          marginRight,
          marginBottom,
          marginLeft,
          padding,
          paddingTop,
          paddingRight,
          paddingBottom,
          paddingLeft,
          visibility,
          lineHeight,
          fontSize: size,
          fontVariant,
          fontWeight: bold ? theme.boldFontWeight : weight,
          textAlign: align,
          textDecoration: underline ? 'underline' : textDecoration,
          fontStyle: italic ? 'italic' : fontStyle,
        },
        (link || href)
            && css`
              color: ${theme.linkColor};
              text-decoration: ${theme.linkTextDecoration};
              cursor: pointer;

              &:hover,
              &:focus {
                color: ${theme.linkColorHover};
                text-decoration: ${theme.linkTextDecorationHover};
              }
            `,
      ]}
      onClick={onClick}
        /**
         *  Set accessibility props automatically if onClick is set, unless overrided
         */
      role={
        role !== undefined ? role
          : onClick && !href ? 'button'
            : undefined
      }
      onKeyPress={
        onKeyPress
          || onClick && (e => e.key === 'Enter' ? onClick(e) : true)
      }
      tabIndex={
        tabIndex !== undefined ? tabIndex : onClick ? 0 : undefined
      }
      href={href}
      {...props}
    >
      {children}
    </Tag>
  );
}) as FC<LabelProps & Partial<ComponentPropsWithoutRef<'span' | 'a'>>>;
